/* eslint-disable no-bitwise */
export enum TaxonomyNames {
	ASSET_TYPE = 'ASSET-TYPE',
	MARKET = 'MARKET',
	SERVICE_TASK_STATUS = 'SERVICE-TASK-STATUS',
	SERVICE_TASK_TYPE = 'SERVICE-TASK-TYPE',
	SCOPE_ASSIGNMENT_TYPE = 'SCOPE-ASSIGNMENT-TYPE',
	SERVICE_CATEGORY = 'SERVICE-CATEGORY',
	PRIORITY = 'PRIORITY',
	SCOPING_MANAGEMENT_STATUS = 'SCOPING-MANAGEMENT-STATUS',
	ASSET_TYPE_CLASS = 'ASSET-TYPE-CLASS',
	LOCATION_TYPE_CLASS = 'LOCATION-TYPE-CLASS',
	WORK_ORDER_TYPE = 'WORK-ORDER-TYPE',
	TIME_ZONE = 'TIME-ZONE',
	RESOURCE_CATEGORY = 'RESOURCE-CATEGORY',
	WORK_ORDER_STATUS = 'WORK-ORDER-STATUS',
	WORK_ORDER_SUB_STATUS = 'WORK-ORDER-SUB-STATUS',
	WORK_ORDER_DECLINE_REASON = 'WORK-ORDER-DECLINE-REASON',
	DAY_TIME = 'DAY-TIME',
}

export enum TaxonomyAttributeNames {
	IS_COMPLETE = 'IsComplete',
	IS_DEFAULT = 'IsDefault',
	IS_DEFAULT_FOR_SELF_PERFORM_ASSIGNMENT = 'IsDefaultForSelfPerformedAssignment',
	IS_SELF_PERFORM = 'IsSelfPerform',
	IS_APPLICABLE_FOR_REMEDY = 'IsApplicableForRemedy',
	LEVEL_OF_IMPORTANCE = 'LevelOfImportance',
	AVAILABLE_FOR = 'AvailableFor',
	ORDER = 'Order',
	GL_CODE_REQUIRED = 'GLCodeRequired',
	PRIORITY_ICON = 'Icon',
}

export enum TaxonomyAttributeValues {
	PRIORITY_ICON_CRITICAL = 'Critical',
}

export enum TaxonomyStatusNames {
	COMPLETE = 'Complete',
	ACTIVE = 'Active',
	ESCALATED = 'Escalated',
	INACTIVE = 'Inactive',
	INCOMPLETE = 'Incomplete',
	VALIDATED = 'Validated',
}

export enum WorkOrderSubStatusNames {
	ADDITIONAL_TECHNICIAN_NEEDED = 'Additional Technician Needed',
	IN_TRANSIT = 'In Transit',
	PENDING_ACCEPTANCE = 'Pending Acceptance',
	PENDING_PAYMENT = 'Pending Payment',
	PENDING_SCHEDULING = 'Pending Scheduling',
	PENDING_VALIDATION = 'Pending Validation',
	RESOURCE_SELECTION = 'Resource Selection',
	RETURN_VISIT_NEEDED = 'Return Visit Needed',
	SCOPE_APPROVED = 'Scope Approved',
	SCOPE_SUBMITTED = 'Scope Submitted',
	SERVICE_COMPLETE = 'Service Complete',
	SERVICE_IN_PROGRESS = 'Service In Progress',
	SERVICE_SCHEDULED = 'Service Scheduled',
	SUPPLY_TRIP = 'Supply Trip',
	TENANT_NO_SHOW = 'Tenant No Show',
	VALIDATED = 'Validated',
	WORK_COMPLETED = 'Work Completed',
	WORK_ORDER_ACCEPTED = 'Work Order Accepted',
	WORK_ORDER_REJECTED = 'Work Order Rejected',
}

export enum DurationUnit {
	Minute = 'min',
	Hour = 'h',
	Day = 'day(s)',
	Week = 'week(s)',
}

export const ScopingManagementStatus = {
	ActiveId: '182bef30-c9ce-406c-9ad5-0b2905dbaec3',
	InactiveId: '1ce8fdb5-cf09-49b5-82a1-9e3671fc1dfd',
	DraftId: 'd3379959-d187-4551-8175-8520544e6aa5',
};

export const ResourceType = {
	User: 'c2dd8e30-94c4-473a-ad54-ed79e6fef169',
};

export const TerritoryType = {
	MarketTerritoryTypeId: '0a16f8c5-7df3-4442-96f9-da79def020fd',
	PodTypeId: '386c5b4c-d53d-4213-987d-f8e4509d03d3',
	ServiceTerritoryTypeId: '5e58212e-4c3f-4f5b-8464-ffd15b64148a',
};

export const ConditionIssueInstanceStatus = {
	Escalated: '0073307d-c3d8-41e8-8996-3d74ae4d3309',
	In_Progress: '2d890eb5-b82f-4551-a176-a665ecafa9e7',
};

export const ConditionIssueInstanceStatusName = {
	Escalated: 'Escalate',
	In_Progress: 'In Progress',
};

export const ServiceTaskStatus = {
	Incomplete: '0a4b0c3e-5668-4870-8355-808b6e981728',
};

export const FeatureFlags = {
	Phase1: '4services_phase1',
	SignalR: '4services_signalr',
	VendorOrders: '4services-vendor-orders',
	PropertyLifecycles: '4-services-te-lifecycles-maintenance-dashboard',
};

export const WorkOrderStatusId = {
	Scheduled: '9184ea1b-565c-412a-90f3-c8dc4545caa4',
	Unscheduled: 'd5e91a24-0f24-4aec-a8f0-d196e554e9ad',
	Completed: 'b26bfc41-5f03-4976-a8d9-312565a40e80',
	InProgress: '35d6ce92-b8c6-4593-bec5-489822c5b74f',
	Cancelled: 'd49b4b07-ed07-481c-b8e7-dcf8e739ba77',
};

export const WorkOrderSubStatusId = {
	ResourceSelection: '59921997-6475-431c-8fa8-b9f89b742e22',
	OutForAcceptance: 'd91e4f6e-148a-4735-99a4-d1b2510b89b0',
};

export const ResourceBookingStatusId = {
	Canceled: '4b266a73-cb9a-4d60-8198-303059287cbc',
	InProgress: 'f384813b-e7c0-4946-a50b-a12eea06c231',
	Traveling: '921c3be8-8564-40fb-bebf-17783521c721',
	Scheduled: 'db98d056-7426-400c-9f4b-3cefc4e7be6a',
	VisitComplete: 'a98e99cc-d085-4765-b5ad-9e0c0629c42e'
};

export const PropertyStatusId = {
	VacantRentedReady: '5193FCDF-DC49-46B4-8BFF-831EC6FE167A',
};

export const ASSET_TYPES_KEY = 'asset_types';
export const ALL_ASSET_TYPES_ATTRIBUTES = 'all-asset-types-attributes';

export interface WorkOrderStatus {
	globalId: string;
	subStatusIds: string[];
}

export const VendorWorkOrderStatuses: WorkOrderStatus[] = [
	{
		globalId: 'd5e91a24-0f24-4aec-a8f0-d196e554e9ad', // Unscheduled
		subStatusIds: [
			'59921997-6475-431c-8fa8-b9f89b742e22', // Resource Selection
			'1549cd3e-d8ae-4beb-b937-341f0215c66d', // Work Order Accepted
			'dd8b51bf-f676-4133-8fd0-05ba9cbc4a2f', // Work Order Rejected
			'e14b29be-4aaa-420e-bcef-e756f4770c17', // Pending Reschedule
			'b5c396a7-8a05-480c-a411-7dceda7a9661', // Tenant No Show
			'cd10db12-381a-44f1-83b8-853ce6442c91', // Return Visit Needed
			'0a028e09-e4ab-4f62-9505-68e70dbd7383', // Additional Technician Needed
			'b6476b97-9ec0-442c-a4b1-ad6ee8fe8bb9', // Awaiting Parts
		],
	},
	{
		globalId: '9184ea1b-565c-412a-90f3-c8dc4545caa4', // Scheduled
		subStatusIds: [
			'4c1ac427-e383-435a-be6c-95d41a972dcf', // Service Scheduled
		],
	},
	{
		globalId: '35d6ce92-b8c6-4593-bec5-489822c5b74f', // In Progress
		subStatusIds: [
			'5d245e0e-0908-4aa4-9b02-07ff80597fa7', // Service In Progress
			'daf626fe-f097-4d7f-8cc6-04ed015fea0c', // Supply Trip
			'120a5b81-af43-4d14-9005-0c2fd15d5ca8', // In Transit
		],
	},
	{
		globalId: 'b26bfc41-5f03-4976-a8d9-312565a40e80', // Completed
		subStatusIds: [
			'18de33b1-851b-4984-a681-f2778302d5e8', // Service Complete
			'1b304b04-70c4-4f5f-90d4-8d115c040744', // Pending Validation
			'ea380908-db6f-4b49-9e4a-af2004d42191', // Pending Payment
			'5257802f-4d80-496f-9bd4-a022f39854a1', // Work Completed
		],
	},
	{
		globalId: 'd49b4b07-ed07-481c-b8e7-dcf8e739ba77', // Cancelled
		subStatusIds: ['fda66a23-acb5-47ae-ab08-336ce9ba7aed'], // Cancelled
	},
];
