import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import React from 'react';
import { AddressModel, OrderDetailsResponse } from 'src/api/api';
import dayjs from 'dayjs';
import getMapUrlByAddress from '../../common/maps';
import OrderList from './OrderList';

export type OrderVendorProps = {
	orders: OrderDetailsResponse[];
	selectedId: string | null;
	selectedDate: dayjs.Dayjs;
};

const getAddressText = (address: AddressModel) => [address.street, address.city, address.state, address.zipCode].join(', ');

const OrderVendor: React.FC<OrderVendorProps> = (props) => {
	const { orders, selectedId, selectedDate } = props;
	const { vendor } = orders[0].order;

	if (!vendor) return null;

	return (
		<Card
			bodyStyle={{ padding: 0 }}
			title={
				<div className="flex justify-between">
					<div className="flex flex-col">
						<span className="font-bold">{vendor.name}</span>
						{vendor.address && (
							<Button href={getMapUrlByAddress(vendor.address)} target="_blank" type="link" icon={<EnvironmentOutlined />} className="pl-0">
								{getAddressText(vendor.address)}
							</Button>
						)}
					</div>
					<Space direction="horizontal">
						{vendor.email && (
							<Button href={`mailto:${vendor.email}`} type="primary" icon={<MailOutlined />}>
								Send an email
							</Button>
						)}
						{vendor.phone && (
							<Button href={`tel:${vendor.phone}`} type="primary" icon={<PhoneOutlined />}>
								Call Vendor
							</Button>
						)}
					</Space>
				</div>
			}
		>
			<OrderList ordersData={orders} loading={false} selectedId={selectedId} selectedDate={selectedDate} />
		</Card>
	);
};

export default OrderVendor;
