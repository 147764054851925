/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';

import { Card, Empty, Spin } from 'antd';
import dayjs from 'dayjs';
import { OrderModel } from '../../api/api';
import { MappedCIs } from './common';
import OrderCard from './OrderCard';
import { OfflineMessage } from './OfflineMessage';

interface OrderListMobileProps {
	loading: boolean;
	isMobile?: boolean;
	orders: OrderModel[];
	mappedCIs: MappedCIs;
	networkError?: boolean;
	selectedDate: dayjs.Dayjs;
	retry?: () => void;
}

const OrderListMobile = (props: OrderListMobileProps) => {
	const { loading, orders, mappedCIs, isMobile, networkError, selectedDate, retry } = props;

	const contentRender = useMemo(() => {
		let content = null;
		if (loading) {
			content = (
				<div className="spin-wrapper">
					<Spin />
				</div>
			);
		} else if (networkError) {
			content = <OfflineMessage retry={retry} />;
		} else if (orders.length === 0) {
			content = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
		}

		if (content) {
			return <Card style={{ borderRadius: 4 }}>{content}</Card>;
		}
		return content;
	}, [loading, networkError, orders.length, retry]);

	return (
		<div className="order-list-mobile">
			{contentRender ?? orders.map((order) => <OrderCard key={order.id} order={order} cCIs={mappedCIs[order.id]} isMobile={isMobile} selectedDate={selectedDate}/>)}
		</div>
	);
};

export default OrderListMobile;
