import { OrderModel } from '@4r/mf-contracts-4services';
import dayjs from 'dayjs';
import { AppointmentModel, ConditionIssueInstanceModel } from '../../api/api';
import { ResourceBookingStatusId } from '../../common/consts';

export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'MM/DD/YYYY';
export type MappedCIs = Record<string, ConditionIssueInstanceModel[]>;
export const NONE = '-';

export const mapAppointmentToOrderModel = (appointment: AppointmentModel): OrderModel => ({
	id: appointment.id,
	name: appointment.typeName,
	typeId: appointment.typeId,
	typeName: NONE,
	property: {
		id: appointment.id,
		address: {},
		geoCoordinates: {
			latitude: appointment.latitude ?? 0,
			longitude: appointment.longitude ?? 0,
		},
	},
	description: appointment.description,
	statusId: NONE,
	statusName: NONE,
	priceListId: '',
	serviceTasks: [],
	notes: [],
	tags: [],
	preferredTimes: [],
	created: '',
	updated: '',
	bookings: [
		{
			id: appointment.id,
			resourceName: '',
			resourceId: '',
			userId: appointment.userId,
			startTime: appointment.startTime,
			endTime: appointment.endTime,
			statusId: '',
		},
	],
});

export const getNewestBookingUntilDate = (order: OrderModel, selectedDate: dayjs.Dayjs, resourceId?: string) => {
	const { bookings } = order;

	const filteredBookings = bookings
		.filter((x) => x.statusId !== ResourceBookingStatusId.Canceled)
		.filter((x) => x.resourceId === resourceId)
		.filter((x) => dayjs(x.startTime).isBefore(selectedDate.add(1, 'day')))
		.sort((a, b) => dayjs(b.startTime).valueOf() - dayjs(a.startTime).valueOf());
		
	return filteredBookings[0] || null;
};