const ROUTES = {
	auth: {
		root: '/auth',
		redirectView: '/auth/login-success',
	},
	LIFECYCLES: 'lifecycles',
};

export default ROUTES;

interface ConfigDetails {
	path: string;
	pageName: string | null;
}

type PathKey =
	| 'orderDetails'
	| 'orders'
	| 'lifecyclesDetails'
	| 'lifecycles'
	| 'inventory'
	| 'approvalsDetails'
	| 'approvals'
	| 'settingsTerritory'
	| 'settingsScope'
	| 'settingsResource'
	| 'assets'
	| 'access'
	| 'vendorProfile'
	| 'vendorAdminOrdersDetails'
	| 'vendorAdminOrders'
	| 'vendorUserOrders'
	| 'bidDetails'
	| 'noAccess'
	| 'status'
	| 'logout'
	| 'home'
	| 'workOrderDetails';

type PathConfig = Record<PathKey, ConfigDetails>;

export const PATH_CONFIG: PathConfig = {
	orderDetails: {
		path: '/orders/:id',
		pageName: 'Order Details',
	},
	orders: {
		path: '/orders',
		pageName: 'Orders List',
	},
	lifecyclesDetails: {
		path: '/lifecycles/:id',
		pageName: 'Property Lifecycles',
	},
	lifecycles: {
		path: '/lifecycles',
		pageName: 'Property Lifecycles',
	},
	inventory: {
		path: '/inventory',
		pageName: 'Inventory',
	},
	approvalsDetails: {
		path: '/approvals/:id',
		pageName: 'Approval',
	},
	approvals: {
		path: '/approvals',
		pageName: 'Approvals',
	},
	settingsTerritory: {
		path: '/settings/territory',
		pageName: 'Territory Management',
	},
	settingsScope: {
		path: '/settings/scope',
		pageName: 'Scoping Management',
	},
	settingsResource: {
		path: '/settings/resource',
		pageName: 'Resource Management',
	},
	assets: {
		path: '/assets',
		pageName: 'Assets',
	},
	access: {
		path: '/access',
		pageName: '4Access',
	},
	vendorProfile: {
		path: '/vendor/profile',
		pageName: '4Vendors',
	},
	vendorAdminOrders: {
		path: '/vendor-admin-orders',
		pageName: 'Orders List',
	},
	vendorAdminOrdersDetails: {
		path: '/vendor-user-orders/:id',
		pageName: 'Order Details',
	},
	vendorUserOrders: {
		path: '/vendor-user-orders',
		pageName: 'Orders List',
	},
	bidDetails: {
		path: '/bids/:id',
		pageName: null,
	},
	noAccess: {
		path: '/no-access',
		pageName: 'No Access',
	},
	status: {
		path: '/status',
		pageName: 'Connection Status',
	},
	logout: {
		path: '/logout',
		pageName: null,
	},
	home: {
		path: '/',
		pageName: '4Services',
	},
	workOrderDetails: {
		path: '/work-orders/:id',
		pageName: null,
	},
};
