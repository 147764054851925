import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Switch, Redirect, Route, Link, useHistory, useLocation, RouteChildrenProps } from 'react-router-dom';
import { MFContext, getService } from '@4r/mf-host';
import { Layout, Row, Col, Button, Drawer, Tag, notification } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { shareFeatureFlaggingContext } from '@4r/module-common-feature-flags-mf-host';
import { AccessContext } from '@4r/module-common-authorization';
import { HANDLE_ERROR_BY_HOST_EVENT } from '@4r/mf-contracts';
import { useConfig, useLocalStorage, utcNow } from '@4r/module-common-mf-app';
import dayjs from 'dayjs';
import { useCurrentLocation } from '@4r/module-common-geolocation';
import ServiceNames from '../services/Services';
import { ILogService } from '../services/LogService/ILogService';
import { CreateLogRequest } from '../api/api';
import { UploadLogsEvent } from '../common/logsDump';
import MessageBusEvent from '../services/MessageBusEvents';
import { UserContext } from '../contexts/UserContext';
import GuardedRouteAutoLogin from '../authentication/GuardedRouteAutoLogin';
import { ReactComponent as LogoSVG } from '../assets/svg/logo_small.svg';
import LeftMenu, { MenuState } from '../components/LeftMenu/LeftMenu';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Footer from '../components/Footer';
import ROUTES, { PATH_CONFIG } from '../routes/constants';
import AuthRoutes from '../authentication/AuthRoutes';
import OrdersPage from './Orders/OrdersPage';
import WorkOrderPage from './WorkOrder/WorkOrderPage';
import InventoryPage from './Inventory/InventoryPage';
import ApprovalsPage from './Approval/ApprovalsPage';
import ApprovalPage from './Approval/ApprovalPage';
import AssetsPage from './Assets/AssetsPage';
import HomePage from './Home/HomePage';
import TerritoryManagementPage from './TerritoryManagement';
import ScopingManagementPage from './ScopingManagement';
import ResourceManagementPage from './ResourceManagement';
import { dispatch, useGlobalState } from '../state/Store';
import PermissionType from '../authorization/PermissionType';
import AccessPropertiesPage from './AccessProperties/AccessPropertiesPage';
import LogoutPage from './Logout/LogoutPage';
import VendorPage from './Vendor/VendorPage';
import NoAccessPage from './NoAccess/NoAccessPage';
import { AuthContext } from '../authentication/AuthProvider';
import getEnvironment from '../common/environment';
import NOTIFICATION_DURATION from '../common/notificationDuration';
import PropertyLifecyclePage from './PropertyLifecycle/PropertyLifecyclePage';
import PropertyLifecycleDetailsPage from './PropertyLifecycle/PropertyLifecycleDetailsPage';
import { PageStatus } from '../state/Actions';
import StatusPage from './Status/StatusPage';
import { ContractsContext } from '../contexts/ContractsContext';
import usePageName, { usePageTitle } from '../common/use-page-name';
import { Settings } from '../contexts/types';
import VendorExp from './VendorExp/VendorExp';
import PageHeader from './PageHeader';

interface MfComponentProps {
	name: string;
	component?: React.FunctionComponent<any>;
}

const MfComponent = (props: MfComponentProps) => {
	const { name, component } = props;
	const mfContext = useContext(MFContext);
	const Container = component ?? mfContext[name];
	const access = useContext(AccessContext);
	const settings = useConfig();
	const history = useHistory();

	if (!Container) return null;

	return <Container currentUser={access.user} history={history} settings={settings} googleApiKey={settings?.GOOGLE_MAP_KEY ?? ''} />;
};

const AppContent: React.FunctionComponent = () => {
	const settings = useConfig();
	const { load } = useContext(MFContext);
	const [version] = useGlobalState('version');
	const auth = useContext(AuthContext);
	const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
	const environment = getEnvironment(settings?.API_URL);
	const screens = useBreakpoint();
	const isMobile = (screens.xs || screens.sm) && !screens.md;
	const user = useContext(UserContext);
	const { contracts } = useContext(ContractsContext);
	const messageBusService = getService('MessageBusService');
	const logService = getService<ILogService>(ServiceNames.Log);
	const { currentLocation, isSuccess, isEnabled } = useCurrentLocation(false);

	// Load Asset MF with MF module v2
	load(
		// filter out the v1 MFs - AccessMicrofrontend
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		(settings as Settings)!.APPS.filter((x) => x.ISV1 !== 'true').map((x) => ({
			config: x.CONFIG,
			css: x.CSS,
			js: x.JS,
			externalStyles: x.EXTERNAL_STYLES,
			enabled: x.ENABLED === 'true',
			name: x.NAME,
		})),
	);

	useEffect(() => {
		// share feature flag context with all MF apps
		shareFeatureFlaggingContext();

		const onlineHandler = () => {
			dispatch({ type: 'setOnlineStatus', status: navigator.onLine ? PageStatus.online : PageStatus.offline });
		};

		window.addEventListener('online', onlineHandler);
		window.addEventListener('offline', onlineHandler);

		return function cleanup() {
			window.removeEventListener('online', onlineHandler);
			window.removeEventListener('offline', onlineHandler);
		};
	}, []);

	const activeMfs = React.useMemo(
		() =>
			Object.keys(contracts)
				.map((key) => ({ Name: key, Contract: contracts[key] }))
				.filter((mf) => (mf.Contract.enabled ?? true) && (mf.Contract.enabledLDFlag ?? true)),
		[contracts],
	);

	const dynamicRoutes: JSX.Element[] = React.useMemo(
		() =>
			activeMfs.flatMap((mf) => (
				<GuardedRouteAutoLogin
					key={mf.Name}
					path={mf.Contract.route ?? ''}
					anyPermissionTypes={Object.values(mf.Contract.leftMenus).flatMap((lm) => lm.anyPermissions ?? [])}
				>
					<MfComponent name={mf.Name} />
				</GuardedRouteAutoLogin>
			)),
		[activeMfs],
	);

	// These are startup components for some MF that require some initialization when the user is authenticated

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const initComponents: JSX.Element[] = React.useMemo(
		() =>
			activeMfs
				.filter((mf) => mf.Contract.initComponent)
				.map((mf) => <MfComponent key={mf.Name} name={mf.Name} component={mf.Contract.initComponent} />),
		[activeMfs],
	);

	const location = useLocation();
	const currentLeftMenu = useMemo(() => {
		const leftMenus = activeMfs.flatMap((x) => Object.values(x.Contract.leftMenus));
		const foundMenu = leftMenus.find((x) => x.route && location.pathname.indexOf(x.route) === 0);
		return foundMenu;
	}, [location, activeMfs]);

	usePageName(currentLeftMenu ? `${currentLeftMenu.title}` : '');

	useEffect(() => {
		const errorListener = messageBusService.on(HANDLE_ERROR_BY_HOST_EVENT, (error: CustomEvent) => {
			notification.error({ message: 'Error occured', description: error.detail, duration: NOTIFICATION_DURATION });
		});

		return () => errorListener.removeListener();
	}, []);

	useEffect(() => {
		const errorListener = messageBusService.on(MessageBusEvent.OfflineCommandSyncingFailed, (error: CustomEvent<string>) => {
			notification.error({ message: 'Offline sync failed', description: error.detail, duration: NOTIFICATION_DURATION });
		});

		return () => errorListener.removeListener();
	}, []);

	useEffect(() => {
		const listener = messageBusService.on(MessageBusEvent.UploadLogsEvent, (event: CustomEvent<UploadLogsEvent>) => {
			const logsDump = event.detail;
			if (!logsDump) {
				return;
			}

			const uploadLogs = async () => {
				let screenSize = 'xxs';
				if (screens.xxl) screenSize = 'xxl';
				else if (screens.lg) screenSize = 'lg';
				else if (screens.md) screenSize = 'md';
				else if (screens.sm) screenSize = 'sm';
				else if (screens.xs) screenSize = 'xs';

				const command = logsDump.command ? { ...logsDump.command, url: undefined, file: undefined } : null;
				const request: CreateLogRequest = {
					name: logsDump.command?.commandType ? `command-${logsDump.command?.commandType}` : 'error',
					// eslint-disable-next-line no-underscore-dangle
					timestamp: logsDump.command?._timestamp ? dayjs(logsDump.command._timestamp).toISOString() : utcNow(),
					payload: {
						userAgent: navigator.userAgent,
						cookieEnabled: navigator.cookieEnabled,
						isGeolocationEnabled: isEnabled,
						isGeolocationAvailable: isSuccess,
						currentGeocoordinates: currentLocation,
						pathName: location.pathname,
						version,
						screenSize,
						isAuthenticated: auth?.isAuthenticated,
						currentUserVendor: user?.current?.vendor,
						command,
						error: logsDump.error,
					},
				};
				await logService.addLog(request);
			};

			uploadLogs();
		});

		return () => listener.removeListener();
	}, []);

	usePageTitle();

	const Content = useMemo(
		() => (
			<Switch>
				<Route path={ROUTES.auth.root} component={AuthRoutes} />
				<GuardedRouteAutoLogin path={PATH_CONFIG.orderDetails.path} permissionType={PermissionType.ViewScheduledWorkOrders}>
					<WorkOrderPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.orders.path} permissionType={PermissionType.ViewScheduledWorkOrders}>
					<OrdersPage dataLoading={false} />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin
					path={PATH_CONFIG.lifecyclesDetails.path}
					anyPermissionTypes={[PermissionType.ViewLifecycles]}
				>
					<PropertyLifecycleDetailsPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin
					path={PATH_CONFIG.workOrderDetails.path}
					anyPermissionTypes={[PermissionType.ViewLifecycles, PermissionType.ViewLifecycleDashboard]}
				>
					{({ match }: RouteChildrenProps<{ id: string }>) => {
						if (!match) return <Redirect to={PATH_CONFIG.lifecycles.path} />;

						const { id } = match.params;

						return <Redirect to={`${PATH_CONFIG.orders.path}/${id}`} />;
					}}
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.lifecycles.path} permissionType={PermissionType.ViewLifecycleDashboard}>
					<PropertyLifecyclePage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.inventory.path} permissionType={PermissionType.ViewInventory}>
					<InventoryPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.approvalsDetails.path} permissionType={PermissionType.ViewApprovals}>
					<ApprovalPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.approvals.path} permissionType={PermissionType.ViewApprovals}>
					<ApprovalsPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.settingsTerritory.path} permissionType={PermissionType.ViewTerritoryData}>
					<TerritoryManagementPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.settingsScope.path} permissionType={PermissionType.ViewScopeManagement}>
					<ScopingManagementPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.settingsResource.path} permissionType={PermissionType.ViewResourceManagement}>
					<ResourceManagementPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.assets.path} permissionType={PermissionType.ViewAssets}>
					<AssetsPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.access.path} permissionType={PermissionType.AccessProperties}>
					<AccessPropertiesPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.vendorProfile.path} permissionType={PermissionType.ViewAssociatedVendor}>
					<VendorPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.vendorAdminOrders.path} permissionType={PermissionType.PersonaExternalAdmin}>
					<VendorExp />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin
					path={PATH_CONFIG.vendorAdminOrdersDetails.path}
					anyPermissionTypes={[PermissionType.PersonaExternalTechnician, PermissionType.PersonaExternalAdmin]}
				>
					<WorkOrderPage />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin path={PATH_CONFIG.vendorUserOrders.path} permissionType={PermissionType.PersonaExternalTechnician}>
					<VendorExp />
				</GuardedRouteAutoLogin>
				<GuardedRouteAutoLogin
					path={PATH_CONFIG.bidDetails.path}
					anyPermissionTypes={[PermissionType.PersonaExternalTechnician, PermissionType.PersonaExternalAdmin]}
				>
					<VendorExp />
				</GuardedRouteAutoLogin>

				<Route path={PATH_CONFIG.noAccess.path} exact>
					<NoAccessPage />
				</Route>
				<Route path={PATH_CONFIG.status.path} exact>
					<StatusPage />
				</Route>
				<Route path={PATH_CONFIG.logout.path} exact>
					<LogoutPage />
				</Route>
				<Route path={PATH_CONFIG.home.path} exact>
					<HomePage />
				</Route>

				{dynamicRoutes}
			</Switch>
		),
		[settings, auth.isAuthenticated, dynamicRoutes],
	);

	const [menuState, setMenuState] = useLocalStorage('menuState', MenuState.Collapsed);

	const menuMargin = () => {
		if (isMobile) {
			return 0;
		}

		return menuState === MenuState.Pinned ? 240 : 80;
	};

	const onCollapse = () => {
		switch (menuState) {
			case MenuState.Collapsed:
				setMenuState(MenuState.Expanded);
				break;

			case MenuState.Expanded:
				setMenuState(MenuState.Pinned);
				break;

			default:
				setMenuState(MenuState.Collapsed);
				break;
		}
	};

	return (
		<Layout style={{ minHeight: isMobile ? window.innerHeight : '100vh' }}>
			{!isMobile ? (
				<Layout.Sider
					width={240}
					style={{ maxHeight: '100%' }}
					className={menuState === MenuState.Pinned ? 'left-menu' : 'left-menu left-menu--collapsible'}
					theme="light"
					collapsible
					collapsed={menuState === MenuState.Collapsed}
					onCollapse={onCollapse}
					trigger={null}
				>
					<div className="left-menu__column">
						<LeftMenu
							menuState={menuState}
							onCollapseChange={onCollapse}
							environment={environment}
							mobile={false}
							menuCollapsed={menuState === MenuState.Collapsed}
							onVendorChange={user.onVendorChange}
							onMenuItemClicked={() => menuState !== MenuState.Pinned && setMenuState(MenuState.Collapsed)}
							vendors={user.current?.vendors || []}
							vendor={user.current?.vendor || null}
						/>
					</div>
				</Layout.Sider>
			) : (
				<Row
					align="middle"
					justify="space-between"
					style={{
						borderBottom: `2px solid ${environment?.color}`,
						padding: '16px',
						background: '#fff',
						position: 'fixed',
						zIndex: 11,
						width: '100%',
						boxShadow: '1px 1px 3px #ccc',
					}}
				>
					<Col>
						<Row align="middle" gutter={12}>
							<Col>
								<Link to="/">
									<LogoSVG style={{ width: 28 }} />
								</Link>
							</Col>
							<Col>
								{environment && !environment.isProduction && (
									<Row justify="center">
										<Tag
											className="left-menu__tag left-menu__tag--mobile"
											style={{
												background: environment.color,
											}}
										>
											{environment.name.toLocaleUpperCase()}
										</Tag>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
					<Col>
						<Button type="link" onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}>
							<MenuOutlined style={{ fontSize: '22px' }} />
						</Button>
					</Col>
					<Drawer
						className="left-menu-mobile"
						bodyStyle={{ padding: 0 }}
						width="100%"
						title={
							<Row justify="space-between" align="middle">
								<Col>
									<Link to="/" onClick={() => setIsMobileDrawerOpen(false)}>
										<LogoSVG style={{ width: 28 }} />
									</Link>
								</Col>
								<Col>
									<Button type="link" onClick={() => setIsMobileDrawerOpen(false)} style={{ padding: '0 6px' }}>
										<CloseOutlined style={{ fontSize: '22px' }} />
									</Button>
								</Col>
							</Row>
						}
						onClose={() => setIsMobileDrawerOpen(false)}
						placement="left"
						closable={false}
						visible={isMobileDrawerOpen}
					>
						<LeftMenu
							menuState={menuState}
							onCollapseChange={onCollapse}
							environment={environment}
							mobile
							menuCollapsed={menuState === MenuState.Collapsed}
							onVendorChange={user.onVendorChange}
							onMenuItemClicked={() => setIsMobileDrawerOpen(false)}
							vendors={user.current?.vendors || []}
							vendor={user.current?.vendor || null}
						/>
					</Drawer>
				</Row>
			)}
			<Layout
				className="site-layout"
				style={{ marginLeft: menuMargin(), marginTop: isMobile ? 58 : 0, background: '#f8f8f8' }}
				onClick={() => menuState === MenuState.Expanded && setMenuState(MenuState.Collapsed)}
			>
				<Layout.Content style={{ display: 'flex', flexDirection: 'column', margin: isMobile ? '16px 6px' : '32px' }}>
					{settings !== null && (
						<>
							<Row className="mt-3 ml-3">
								<Breadcrumbs
									dynamicRoutes={[
										{ path: '/no-access', breadcrumb: 'No Access' },
										{ path: '/orders/:id', breadcrumb: null },
										{ path: '/approvals/:id', breadcrumb: null },
										{ path: '/lifecycles/:id', breadcrumb: null },
										{ path: '/vendor-user-orders/:id', breadcrumb: null },
									]}
								/>
							</Row>
							<PageHeader />

							{Content}

							{initComponents}
						</>
					)}
				</Layout.Content>
				<Footer />
			</Layout>
		</Layout>
	);
};

export default AppContent;
